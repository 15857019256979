
import http from "@/http";
import { Breadcrumb, Checkitem } from "@/interfaces";
import Vue from "vue";
import { Route } from "vue-router";
import { mdiImage } from "@mdi/js";
import { date } from "@/filters";

export interface DataType {
  checkitem?: Checkitem;
}

export default Vue.extend({
  filters: {
    date
  },
  data(): DataType {
    return {
      checkitem: undefined
    };
  },
  async beforeRouteEnter(to: Route, from: Route, next: any) {
    const url = `/checkitems/${to.params.id}`;

    const { data } = await http.get<Checkitem>(url);

    next((vm: DataType & Vue) => {
      vm.checkitem = data;
    });
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      if (this.checkitem == null) {
        return [];
      }

      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "室内状況の確認",
          to: `/checklist/${this.checkitem.checklist_id}`
        },
        {
          text: this.checkitem.room,
          disabled: true
        }
      ];
    },
    icon() {
      return {
        mdiImage
      };
    }
  }
});
